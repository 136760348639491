// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Picture from "../../../styleguide/forms/Picture/Picture.res.js";
import * as IconDelete from "../../../styleguide/icons/IconDelete.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as BlogPostEditFormScss from "./BlogPostEditForm.scss";

var css = BlogPostEditFormScss;

function BlogPostExistingImage$Image(props) {
  var onDelete = props.onDelete;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(Picture.make, {
                      src: props.url,
                      large: [
                        500,
                        250
                      ],
                      crop: "Fit"
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(IconDelete.make, {
                            size: "LG",
                            color: "DarkGray",
                            viewBoxSize: "20"
                          }),
                      className: css.iconDelete,
                      onClick: (function (param) {
                          onDelete();
                        })
                    })
              ],
              className: css.imageContainer
            });
}

var $$Image = {
  make: BlogPostExistingImage$Image
};

function BlogPostExistingImage(props) {
  var removeImage = props.removeImage;
  var url = props.url;
  return JsxRuntime.jsx(JsxRuntime.Fragment, {
              children: Caml_option.some(url !== undefined ? JsxRuntime.jsx(BlogPostExistingImage$Image, {
                          url: url,
                          onDelete: (function () {
                              removeImage(url);
                            })
                        }, "blog-post-image") : null)
            });
}

var make = BlogPostExistingImage;

export {
  css ,
  $$Image ,
  make ,
}
/* css Not a pure module */
